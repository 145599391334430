import React from 'react';
import ChatMessage from './ChatMessage';

const Chat = ({ messages, INITIAL_MESSAGE, messagesEndRef }) => {
    const greetings = INITIAL_MESSAGE.split('\n').filter(msg => msg.trim() !== '');

    return(
        <div className="chat-messages">
            {/* 시작 멘트 */}
            {greetings.map((message, index) => (
                <div key={index} className='message chatbot'>
                    {message}
                </div>
            ))}
            {/* 채팅 */}
            {messages.map((msg) => (
                <ChatMessage key={msg.id} msg={msg}/>
            ))}
            <div ref={messagesEndRef}/>
        </div>
    );
};

export default Chat;