import React from 'react';
import './App.css';

import Chat from './components/Chat';
import ChatInput from './components/ChatInput';
import Header from './components/Header';

import { useChat } from './hooks/useChat';
import { useView } from './hooks/useView';

const INITIAL_MESSAGE = "안녕하세요. 골프몬 검색 서비스 입니다.\n원하시는 조건의 골프 상품을 찾아드리겠습니다.\n시간, 지역, 가격등 편하게 말씀해주세요.";

const Chatbot = () => {
  useView(); // 모바일 높이 조정;
  const {
    messages, text, isLoading,
    onScenario, setText, messagesEndRef
  } = useChat();

  return (
    <div className="chat-wrapper">
      <div className="chat-container">

        <Header/>
        
        <Chat
          messages={messages}
          INITIAL_MESSAGE={INITIAL_MESSAGE}
          messagesEndRef={messagesEndRef}
        />
        
        <ChatInput
          text={text}
          setText={setText}
          handleSubmit={onScenario}
          isLoading={isLoading}
        />

      </div>
    </div>
  );
};

export default Chatbot;
