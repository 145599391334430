import { useEffect } from "react";

export const useView = () => {
    const setScreenSize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    useEffect(() => {
        setScreenSize();

        const handleScreen = () => setScreenSize()
        window.addEventListener('resize', handleScreen);
        return () => {
            window.removeEventListener('resize', handleScreen);
        };
    }, []);
};
