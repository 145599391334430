import React from "react";
import logo from '../assets/images/golfmon_logo.png';

const Header = () => {
    const handleHome = () => {window.location.href='/';};
    
    // 로고 클릭 시, 모바일 pc 이동 구분
    const handleImg = () => {
        if (window.innerWidth <= 768){
            window.location.href='https://m.golfmon.net/#!/home';
        } else{
            window.location.href="https://golfmon.net/renewal/?pageName=main";
        }        
    }

    return (
        <div className="chat-header">
          <img className='logo' src={logo} alt='골프몬 로고' onClick={handleImg}/>
          <button className='home-button' onClick={handleHome}>재시작</button>
        </div>
    );
}

export default Header;