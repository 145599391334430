import { useState } from "react";
import { sendUserMessage } from "../Repository/Message";
import useScenario from "./useScenario";
import { useScroll } from "./useScroll";

export const useChat = () => {
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    const [keyword, setKeyword] = useState('keyword');
    const [metaData, setMetaData] = useState(null);
    const [packageData, setPackageData] = useState([]);

    const { keywordLogic, showPackageLogic } = useScenario(
        setKeyword,
        setMetaData,
        setPackageData,
        setMessages,
    )

    const messagesEndRef = useScroll(messages);

    // Method  
    const onScenario = async (e) => {
        e.preventDefault()
        if (!text.trim() || isLoading) return;

        
        // user 
        const userMessage = await sendUserMessage(text);
        setMessages(prev => [...prev, userMessage]);

        setText('');
        
        try {
            setIsLoading(true);
            
            // chatbot 
            if (keyword === 'keyword') {
                await keywordLogic(text, metaData);
            } else if (keyword === 'show_package') {  
                await showPackageLogic(text, packageData);
            }  
             
        } catch (error) {
            console.error('Chat Error:', error);
            alert('메시지 전송에 실패했습니다.');
        } finally {
            setIsLoading(false);  
        } 
    }

    return {
        messages, 
        text, 
        isLoading,
        onScenario,
        setText,
        messagesEndRef
    }
}