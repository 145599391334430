import React, { useEffect, useRef } from 'react';

const ChatInput = ({ text, setText, handleSubmit, isLoading }) => {
    const textAreaRef = useRef(null);

    const handleTextArea = () => {
        const textarea = textAreaRef.current;
        if (!textarea) return;

        // 높이 조절
        textarea.style.height = 'auto';
        const scrollHeight = textarea.scrollHeight
        const newHeight = Math.min(scrollHeight, 72);
        textarea.style.height = `${newHeight}px`;

        // 스크롤 바
        if (scrollHeight >= 72) {
            textarea.classList.add('show-scrollbar');
        } else {
            textarea.classList.remove('show-scrollbar');
        };
    }
    
    useEffect(()=>{
        handleTextArea();
    }, [text]);

    return (
        <div className="chat-input-container">
            <div className="chat-input-wrapper">
                {/* 채팅 입력 */}
                <textarea
                    ref={textAreaRef}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="메시지를 입력해주세요"
                    className="chat-input"
                    rows="1"
                    disabled={isLoading}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                />
                <button 
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="send-button"
                >전송
                </button>
            </div>
        </div>
    );
};

export default ChatInput;