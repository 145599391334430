import React from "react";
import Products from "./Products";

const ChatMessage = ({msg}) => {
    const startWord = msg.text.startsWith("기타");
    
    const botMessage = (
        <div
            key={msg.id}
            className={`message ${msg.type}`}
        >{msg.text}
        </div>
    )
    
    const productCount = msg.max_count;
    const productCountMessage = msg.products && productCount >= 4 && (
        <div className={`message ${msg.type}`}>
        {`${productCount}개의 결과 중 상위 항목을 보여드리겠습니다.`}
        </div>
    );

    const productMessage = msg.products && (
        <Products data={msg.products}/>
    )

    return startWord ? ( 
        <>
            {productCountMessage}
            {productMessage}
            {botMessage}
        </>
    ): (
        <>
            {botMessage}
            {productCountMessage}
            {productMessage}
        </>
    );
};

export default ChatMessage;